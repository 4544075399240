import { useTitle } from '@vueuse/core'
import { createApp } from 'vue'
import 'uno.css'
import '@unocss/reset/tailwind.css'

import Domain from './Domain.vue'

const app = createApp(Domain)

app.mount('#app')

useTitle(import.meta.env.VITE_APP_NAME)
